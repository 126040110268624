* {
  box-sizing: border-box;
}

body, div, p {
  margin: 0;
  padding: 0;
}

:root {
  font-family: Arial, Helvetica, sans-serif;
  --parchment:#ebe8e0;
  --dark-parchment:#a79f8c;
  --aya-lighter-gray:#d6d6d6;
  --aya-light-gray:#adadad;
  --aya-gray:#838383;
  --aya-dark-gray:#5a5a5a;
  --aya-darker-gray:#272727;
  --aya-lighter-blue:#57acff;
  --aya-light-blue:#006ad2;
  --aya-blue:#0054a6;
  --aya-dark-blue:#00366a;
  --aya-darker-blue:#001a33;
  --aya-lighter-red:#fa8796;
  --aya-light-red:#e44e62;
  --aya-red:#911425;
  --aya-dark-red:#530812;
  --aya-darker-red:#1d0206;
  --aya-lighter-green:#87fa96;
  --aya-light-green:#4ee462;
  --aya-green:#1c9257;
  --aya-dark-green:#085325;
  --aya-darker-green:#1d0206;
  --aya-lighter-yellow:#fffbc6;
  --aya-light-yellow:#ece473;
  --aya-yellow:#bda834;
  --aya-dark-yellow:#695c11;
  --aya-darker-yellow:#2c2606;
  --aya-lighter-purple:#bb8ff5;
  --aya-light-purple:#894cda;
  --aya-purple:#6627b8;
  --aya-dark-purple:#3e1277;
  --aya-darker-purple:#180531;
  --default-font-color:white;
  --dark-font-color: black;
  --transparent-darken:#3d3d3d63;
}
@media (min-width: 1100px) {
  :root {
    font-size: 12pt;
  }
}
@media (max-width: 1099px) {
  :root {
    font-size: 20pt;
  }
}

body {
  background-image: linear-gradient(to right, var(--aya-darker-gray) 0%, var(--aya-gray) 25%, var(--aya-gray) 75%, var(--aya-darker-gray) 100%);
}

h1 {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  font-family: "Times New Roman", Times, serif;
}

textarea {
  width: 100%;
}

label {
  user-select: none;
}

input[type=text] {
  width: 100%;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: rgba(68, 68, 68, 0.1333333333);
  box-shadow: inset 0 0 3px grey;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid var(--aya-gray);
  background: var(--dark-parchment);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--parchment);
}

::-webkit-scrollbar-thumb:active {
  background: var(--aya-dark-gray);
}

.center-align {
  margin-left: auto;
  margin-right: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flex-center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

#main-header {
  width: 100%;
  height: 250px;
}

#banner-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 64px;
  background-size: 300% 64px;
  border-bottom: 3px solid var(--aya-darker-blue);
  background-image: linear-gradient(179.5deg, var(--aya-light-blue), var(--aya-darker-blue));
  background-attachment: local;
  animation: gradient-change 20s alternate infinite;
}
#banner-container #banner-left-margin {
  flex: 1;
}
#banner-container #banner-contents {
  display: flex;
  flex-direction: row;
  width: 1104px;
  margin: 0;
  padding: 0;
}
#banner-container #banner-right-margin {
  flex: 1;
}

#nav-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(to bottom, var(--aya-darker-gray) 0%, var(--aya-dark-gray) 20%, var(--aya-dark-gray) 85%, var(--aya-gray) 100%);
  border-bottom: 2px solid var(--aya-darker-gray);
}
#nav-container #nav-left-margin {
  flex: 1;
}
#nav-container #nav-bar-container {
  width: 1104px;
  padding: 0 8px;
}
#nav-container #nav-bar-container nav {
  display: flex;
  flex-direction: row;
  text-decoration: none;
}
#nav-container #nav-bar-container nav a {
  text-decoration: none;
  user-select: none;
}
#nav-container #nav-bar-container .link-container {
  height: 34px;
}
#nav-container #nav-bar-container .link-container:not(:first-child) {
  margin-left: 4px;
}
#nav-container #nav-bar-container .link-container:not(:last-child) {
  margin-right: 4px;
}
#nav-container #nav-bar-container .link-container .link-decoration, #nav-container #nav-bar-container .link-container .link,
#nav-container #nav-bar-container .link-container p {
  height: 34px;
  padding: 8px;
  margin-right: 4px;
  width: auto;
  color: white;
  font-weight: bold;
  user-select: none;
}
#nav-container #nav-bar-container .link-container .link,
#nav-container #nav-bar-container .link-container p {
  text-decoration: none;
  cursor: pointer;
}
#nav-container #nav-bar-container .link-container .link:hover,
#nav-container #nav-bar-container .link-container p:hover {
  color: lightgray;
}
#nav-container #nav-right-margin {
  flex: 1;
}

@keyframes gradient-change {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 100%;
  }
}
#page-container {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: row;
}
#page-container #left-margin {
  flex: 1;
}
#page-container #content-container {
  width: 1104px;
  background-color: var(--aya-lighter-gray);
  border-right: 2px solid var(--aya-dark-gray);
  border-bottom: 2px solid var(--aya-dark-gray);
  border-left: 2px solid var(--aya-dark-gray);
}
#page-container #content-container.use-padding {
  padding: 10px;
}
#page-container #right-margin {
  flex: 1;
}

