.character-trait-panel-container {
    width: 480px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid darkgray;

    .active-indicator {
        width: 32px;
        border-radius: 10px 0 0 10px;

        &.editable {
            cursor: pointer;
        }

        &.active {
            background-color: green;
        }

        &.inactive {
            background-color: gray;
        }
    }

    .details-container {
        left: -20px;
        width: 100%;
        height: 100%;
        margin: 8px;

        .title {
            font-weight: bold;
            margin-right: 16px;
        }

        .type {
            font-style: italic;
        }
    }
}
