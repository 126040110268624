.whitelist-editor-container {
    .unassigned-list {
        width: 200px;
        height: 400px;
    }

    .viewers-toolbar {
        width: 36px;
        height: 200px;
        padding-top: 60px;
        margin: 0 8px;

        button {
            width: 32px;
            height: 32px;
        }
    }

    .viewers-list {
        width: 200px;
        height: 154px;
    }

    .editors-toolbar {
        width: 36px;
        height: 200px;
        padding-top: 60px;
        margin: 0 8px;

        button {
            width: 32px;
            height: 32px;
        }
    }

    .editors-list {
        width: 200px;
        height: 154px;
    }

    .between-toolbar {
        width: 216px;
        height: 32px;
        padding-left: 106px;

        button {
            width: 32px;
            height: 32px;
        }
    }
}
