.numeric-slider-container {
    width: 100%;

    .label {
        margin-right: 4px;
        padding-top: 2px;
        font-weight: bold;
        user-select: none;
        -webkit-user-select: none;
        -webkit-user-drag: none;
    }

    .slider {
        display: inline-grid;
        width: 100%;
        min-width: 40px;
        height: 24px;
        margin: 0 12px 0 12px;

        .slider-bar {
            grid-column: 1;
            grid-row: 1;
            margin: 8px 0;
            background-color: white;
            border: 1px solid black;
        }

        .filled-section {
            grid-column: 1;
            grid-row: 1;
            margin: 8px 0;
            background-color: red;
            border: 1px solid black;
        }

        .slider-handle {
            position: relative;
            grid-column: 1;
            grid-row: 1;
            width: 24px;
            height: 24px;
            transform: translateX(-50%);
            border-radius: 50%;
            user-select: none;
            -webkit-user-select: none;
            -webkit-user-drag: none;

            &:hover {
                background-color: #00000055;
            }

            &:active {
                background-color: #ffffff55;
            }
        }
    }

    .value {
        width: 50px;
        margin: 0 0 0 4px;
        padding: 2px 4px 2px 4px;
        -webkit-user-drag: none;
    }
}
