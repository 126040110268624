.card-body {
  min-height: 32px;
  width: calc(100% - 16px);
  border: 1px solid lightgray;
  border-radius: 8px;
  background-color: white;
  margin: 8px;
  padding: 12px;
  z-index: 5;
  filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.1333333333));
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.card-body.clickable.disabled:hover {
  outline: 4px solid #b9b9b9;
}
.card-body.clickable:not(.disabled) {
  cursor: pointer;
}
.card-body.clickable:not(.disabled):hover {
  outline: 4px solid #70e7f0;
}
.card-body.clickable:not(.disabled):active {
  outline: 6px solid #5fcdd4;
}
.card-body h2 {
  font-size: 1.5em;
  margin: 0 0 12px 0;
}

