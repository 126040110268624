.list-menu-click {
    user-select: none;
    -webkit-user-select: none;
}

.list-menu-hover {
    user-select: none;
    -webkit-user-select: none;

    &:hover .list-menu-container {
        display: block;
    }
}

.list-menu-container {
    display: none;
    min-width: 100px;
    width: 200px;
    min-height: 5px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    border: 4px solid double gray;
    background-color: white;
    z-index: 100;
    box-shadow: 3px 3px 8px gray;
    -webkit-box-shadow: 3px 3px 8px gray;

    &.hover-type {
        &:hover {
            display: block;
        }
    }

    &.click-type {
        &.visible {
            display: block;
        }
    }
}

.list-menu-item {
    margin: 0;
    background-color: white;
    color: black;
    min-height: 20px;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: pointer;

    &:hover {
        background-color: darkcyan;
        color: white;
    }

    &:active {
        background-color: gray;
        color: black;
    }
}
