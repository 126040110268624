.character-school-panel-container {
  width: 480px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid darkgray;
}
.character-school-panel-container .icon-container {
  width: 64px;
  height: 64px;
  margin: 16px 4px;
}
.character-school-panel-container .icon-container .icon {
  width: 64px;
  height: 64px;
  border-radius: 8px;
}
.character-school-panel-container .details-container {
  width: 100%;
  height: 100%;
  margin: 8px;
}
.character-school-panel-container .details-container .title {
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 16px;
}
.character-school-panel-container .details-container .type {
  font-style: italic;
}
.character-school-panel-container .level-container {
  display: inline-grid;
  width: 60px;
  height: 60px;
  margin: 4px;
}
.character-school-panel-container .level-container .level-circle {
  width: 100%;
  height: 100%;
  grid-row: 1;
  grid-column: 1;
}
.character-school-panel-container .level-container .level-value {
  width: 100%;
  height: 100%;
  grid-row: 1;
  grid-column: 1;
}
.character-school-panel-container .level-container .level-value .level-text {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  font-size: 20pt;
  -webkit-user-select: none;
  user-select: none;
}

.school-subtract-button {
  color: red;
  cursor: pointer;
  width: 20px;
  height: 100%;
  font-weight: bold;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}
.school-subtract-button:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.school-subtract-button:active {
  color: darkred;
  background-color: rgba(0, 0, 0, 0.2666666667);
}

.school-add-button {
  color: green;
  cursor: pointer;
  width: 20px;
  height: 100%;
  font-weight: bold;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}
.school-add-button:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.school-add-button:active {
  color: darkgreen;
  background-color: rgba(0, 0, 0, 0.2666666667);
}

