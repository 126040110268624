.positive-negative-meter {
  display: inline-grid;
  width: 100%;
  height: 25px;
}
.positive-negative-meter .fill-space {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: lightgrey;
  -webkit-box-shadow: inset 0 0 20px 6px black;
  box-shadow: inset 0 2px 20px 6px black;
}
.positive-negative-meter .fill-space.editable {
  cursor: pointer;
}
.positive-negative-meter .fill-space .fill-space-left {
  display: flex;
  flex-direction: row-reverse;
  width: 50%;
  height: 100%;
}
.positive-negative-meter .fill-space .fill-space-left .fill {
  width: 30%;
  height: 100%;
  background-color: white;
  box-shadow: inset 8px -2px 10px 6px rgba(0, 0, 0, 0.7);
  border-right: 1px solid black;
  border-left: 2px solid black;
}
.positive-negative-meter .fill-space .fill-space-right {
  width: 50%;
  height: 100%;
}
.positive-negative-meter .fill-space .fill-space-right .fill {
  width: 50%;
  height: 100%;
  background-color: white;
  box-shadow: inset -8px -2px 10px 6px rgba(0, 0, 0, 0.7);
  border-right: 2px solid black;
  border-left: 1px solid black;
}
.positive-negative-meter .editor-overlay {
  width: 100%;
  height: 100%;
  grid-column: 1;
  grid-row: 1;
  background-color: purple;
}
.positive-negative-meter .editor-overlay input[type=number] {
  width: 100%;
  height: 100%;
  font-size: 14pt;
}

