@import "../../../styles/main_style.css";

.button-style {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
    padding: 4px;
    border: 1px solid black;
    border-radius: 5px;

    &.enabled {
        cursor: pointer;
        color: black;

        &:hover {
            background-color: var(--aya-lighter-gray);
        }

        &:active {
            background-color: lightblue;
        }
    }

    &.disabled {
        cursor: default;
        color: gray;
    }
}

.options-style {
    max-height: 200px;
    overflow-y: auto;
    padding: 4px;
    border: 1px solid var(--aya-lighter-gray);
    background-color: white;
    box-shadow: 3px 3px 6px gray;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
}

.option-style {
    min-height: 1em;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 2px;
    color: black;

    &:hover {
        background-color: lightblue;
    }

    .icon-style {
        width: 20px;
        height: 20px;
    }
}
