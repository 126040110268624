.character-sheet-container .character-sheet {
  min-height: 840px;
}
.character-sheet-container .character-sheet h2 {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.character-sheet-container .character-sheet .character-sheet-row-span-1 {
  width: 550px;
  padding: 8px;
}
.character-sheet-container .character-sheet .character-sheet-row-span-2 {
  width: 1100px;
  padding: 8px;
}
.character-sheet-container .character-sheet .character-sheet-row-span-2.no-padding {
  padding: 0;
}
.character-sheet-container .character-sheet .hp-meter {
  width: 100%;
}
.character-sheet-container .character-sheet .ep-meter {
  width: 100%;
}
.character-sheet-container .character-sheet .ep-meter-adjust {
  margin-top: 6px;
  width: 120px;
}
.character-sheet-container .character-sheet .mp-meter {
  width: 100%;
}
.character-sheet-container .character-sheet .mp-meter-adjust {
  margin-top: 6px;
  width: 120px;
}
.character-sheet-container .character-sheet .exhaustion-label {
  margin-top: 10px;
  width: 140px;
}
.character-sheet-container .character-sheet .exhaustion-col {
  margin-top: 6px;
  width: 140px;
}
.character-sheet-container .character-sheet .dmg-switch-button {
  width: 24px;
  height: 24px;
}
.character-sheet-container .character-sheet .features-container {
  width: 100%;
  height: 540px;
}
.character-sheet-container .character-sheet .features-container .features-content-panel {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.character-sheet-container .character-sheet .features-container .features-sub-container {
  width: 100%;
  height: 100%;
}
.character-sheet-container .character-sheet .features-container .features-sub-container .features-sub-content-panel {
  width: 100%;
  height: 100%;
}

