.numeric-slider-container {
  width: 100%;
}
.numeric-slider-container .label {
  margin-right: 4px;
  padding-top: 2px;
  font-weight: bold;
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}
.numeric-slider-container .slider {
  display: inline-grid;
  width: 100%;
  min-width: 40px;
  height: 24px;
  margin: 0 12px 0 12px;
}
.numeric-slider-container .slider .slider-bar {
  grid-column: 1;
  grid-row: 1;
  margin: 8px 0;
  background-color: white;
  border: 1px solid black;
}
.numeric-slider-container .slider .filled-section {
  grid-column: 1;
  grid-row: 1;
  margin: 8px 0;
  background-color: red;
  border: 1px solid black;
}
.numeric-slider-container .slider .slider-handle {
  position: relative;
  grid-column: 1;
  grid-row: 1;
  width: 24px;
  height: 24px;
  transform: translateX(-50%);
  border-radius: 50%;
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}
.numeric-slider-container .slider .slider-handle:hover {
  background-color: rgba(0, 0, 0, 0.3333333333);
}
.numeric-slider-container .slider .slider-handle:active {
  background-color: rgba(255, 255, 255, 0.3333333333);
}
.numeric-slider-container .value {
  width: 50px;
  margin: 0 0 0 4px;
  padding: 2px 4px 2px 4px;
  -webkit-user-drag: none;
}

