.multi-line-text-input-toggle-field-container {
    width: 100%;
    min-height: 24px;

    .label {
        font-weight: bold;
    }

    .display-field {
        word-wrap: normal;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 24px;
        padding: 8px;
        border-radius: 5px;

        &.placeholder {
            color: gray;
        }

        &.editable {
            cursor: pointer;

            &:hover {
                background-color: #00000022;
            }

            &:active {
                background-color: #00000044;
            }
        }
    }
}
