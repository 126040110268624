.article-container {
    .article-content {
    }

    .hidden-field {
        display: none;
    }

    .map_container {
        width: 320px;
        height: 240px;
        margin: 4px;
        overflow: hidden;

        img {
            &:first-child {
                width: 320px;
            }
        }
    }

    .article-category-bar {
        clear: both;
        width: 100%;
        padding: 8px;
        border: 1px solid darkgray;
        border-radius: 4px;
        font-size: 0.9em;
        background-color: #00000011;
    }

    .article-footer {
        clear: both;
        display: flex;
        flex-direction: row;
        font-size: 0.8em;
        color: #444444;
        padding: 8px;

        .footer-left {
            width: 50%;
        }

        .footer-right {
            width: 50%;
            text-align: right;
        }
    }
}
