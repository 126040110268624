.wallet-container {
  border: 1px solid darkgray;
  border-radius: 6px;
}
.wallet-container .wallet-header {
  background-color: #c0c0c0;
  padding: 8px;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}
.wallet-container .wallet-header .wallet-header-label {
  padding-top: 6px;
}
.wallet-container .wallet-header button {
  width: 28px;
  height: 28px;
  padding: 2px;
}
.wallet-container .wallet-header .add-button {
  width: 28px;
  height: 28px;
  padding: 2px;
}
.wallet-container .currencies-wrapper {
  padding: 8px;
}
.wallet-container .currencies-wrapper.hidden {
  display: none;
}
.wallet-container .currency-container {
  margin: 2px 0;
}
.wallet-container .currency-container .currency-header {
  background-color: #c0c0c0;
  padding: 8px;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
}
.wallet-container .currency-container .currency-header.collapsed {
  border-radius: 6px;
}
.wallet-container .currency-container .denominations-container {
  padding: 4px;
  border: 1px solid darkgray;
  border-radius: 0 0 6px 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.wallet-container .currency-container .denominations-container .denomination-row {
  padding: 2px;
}
.wallet-container .currency-container .denominations-container .denomination-row .denomination-name {
  padding: 6px 0 2px 0;
}
.wallet-container .currency-container .denominations-container .denomination-row .denomination-plain-value {
  padding: 4px 0 2px 0;
}
.wallet-container .currency-container .denominations-container .total-row {
  padding: 4px 4px 4px 2px;
  background-color: #c8c8c8;
}

