.item-icon-editor-container .icon-list-container {
  width: 340px;
  height: 192px;
  overflow-y: scroll;
}
.item-icon-editor-container .icon-list-container .icon {
  cursor: pointer;
}
.item-icon-editor-container .icon-list-container .icon.selected {
  filter: opacity(0.5) drop-shadow(0 0 0 green);
}
.item-icon-editor-container .result-container {
  margin: 0 50% 0 50%;
}
.item-icon-editor-container .result-icon {
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

