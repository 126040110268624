.whitelist-editor-container .unassigned-list {
  width: 200px;
  height: 400px;
}
.whitelist-editor-container .viewers-toolbar {
  width: 36px;
  height: 200px;
  padding-top: 60px;
  margin: 0 8px;
}
.whitelist-editor-container .viewers-toolbar button {
  width: 32px;
  height: 32px;
}
.whitelist-editor-container .viewers-list {
  width: 200px;
  height: 154px;
}
.whitelist-editor-container .editors-toolbar {
  width: 36px;
  height: 200px;
  padding-top: 60px;
  margin: 0 8px;
}
.whitelist-editor-container .editors-toolbar button {
  width: 32px;
  height: 32px;
}
.whitelist-editor-container .editors-list {
  width: 200px;
  height: 154px;
}
.whitelist-editor-container .between-toolbar {
  width: 216px;
  height: 32px;
  padding-left: 106px;
}
.whitelist-editor-container .between-toolbar button {
  width: 32px;
  height: 32px;
}

