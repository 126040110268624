.list-container {
  background-color: white;
  border: 1px solid black;
  overflow-x: hidden;
  overflow-y: scroll;
}

.list-item-container {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  background-color: white;
  padding: 1px 4px;
}
.list-item-container.selected {
  background-color: lightblue;
}

