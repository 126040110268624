.ability-icon {
    width: 64px;
    height: 64px;
}

.ability-icon-placeholder {
    width: 64px;
    height: 64px;
    border: 1px solid black;
}
