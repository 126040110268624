.generic-table-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;

    .generic-table-header-container {
        font-weight: bold;

        .generic-table-header-cell {
            width: 100%;
            padding: 2px;
            user-select: none;
            -webkit-user-select: none;
            overflow-x: hidden;

            &:fixed-width {
                flex-shrink: 0;
            }

            &:not(:last-child) {
                border-right: 1px solid darkgray;
            }

            &.sortable {
                cursor: pointer;

                &:hover {
                    background-color: skyblue;
                }

                &:active {
                    background-color: darkgray;
                }
            }

            .label {
                margin-left: 4px;
                overflow-x: hidden;
            }

            .sort-button {
                width: 24px;
                height: 24px;
                cursor: pointer;
                margin-right: 4px;

                img {
                    width: 20px;
                    height: 20px;
                }

                &:hover {
                    background-color: skyblue;
                }

                &:active {
                    background-color: darkgray;
                }
            }
        }
    }

    .generic-table-rows-container {
        .generic-table-row-wrapper {
            width: 100%;

            &:not(:last-child) {
                border-bottom: 1px solid darkgray;
            }

            &.selected {
                background-color: lightblue;
            }

            .generic-table-row {
                width: 100%;

                .generic-table-cell {
                    padding: 2px;
                    margin: 20px;
                    overflow-x: hidden;
                    margin: 0;
                }
            }
            .generic-table-row-footer {
                width: 100%;
            }
        }
    }
}
