.radio-button-container {
    .button-element {
        width: 20px;
        height: 20px;
        cursor: pointer;
        border-radius: 50%;

        &:hover {
            background-color: #00000033;
        }
    }

    .label-element {
        margin: 0 4px 0 4px;
        cursor: pointer;
        user-select: none;
    }
}
