.proficiency-panel-container {
    width: 100px;
    height: 120px;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;

    &:hover .dynamicfill {
        fill: #aaa;
    }

    .proficiency-panel-frame {
        position: absolute;
        left: 10%;
        width: 90%;
    }

    .proficiency-panel-value-container {
        grid-column: 1;
        grid-row: 1;
    }

    .title {
        position: absolute;
        left: 10%;
        top: 10%;
        width: 90%;
        text-align: center;
        font-size: 80%;
        font-weight: bold;
        user-select: none;
    }

    .score-container {
        position: absolute;
        left: 18%;
        top: 30%;
        width: 74%;
        height: 30%;
        border: 1px black solid;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: #bbb;
    }

    .score-field {
        width: 100%;
        height: 100%;
        font-size: 150%;
        align-content: center;
        text-align: center;
        box-sizing: border-box;
        user-select: none;

        &.editable {
            &:hover {
                background-color: #00000022;
            }

            &:active {
                background-color: #00000044;
            }
        }
    }

    .score-editor {
        position: absolute;
        width: 100%;
        height: 100%;
        font-size: 1.25rem;
        box-sizing: border-box;
        border-radius: 5px;

        box-sizing: border-box;
        user-select: none;
        -webkit-user-select: none;

        .value {
            width: 100%;
            height: 100%;
            font-size: 1.25rem;
            align-content: center;
            text-align: center;

            &:hover {
                background-color: #00000022;
            }
            &:active {
                background-color: #00000044;
            }
        }

        .mod-button {
            cursor: pointer;
            width: 20px;
            height: 100%;
            border-radius: 5px;
            align-content: center;
            text-align: center;

            &:hover {
                background-color: #00000022;
            }
            &:active {
                background-color: #00000044;
            }
        }
    }

    .modifier-container {
        position: absolute;
        top: 68%;
        left: 20%;
        width: 70%;
        height: 22%;
        border: 1px black solid;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: #bbb;
    }

    .modifier {
        width: 100%;
        height: 100%;
        font-size: 90%;
        align-content: center;
        text-align: center;
        box-sizing: border-box;
        user-select: none;
    }
}
