.item-editor-container .icon-toolbar-container {
  border: 1px solid black;
  width: 80px;
}
.item-editor-container .icon-toolbar-container .icon-container {
  width: 100%;
  margin: 4px 8px 4px 8px;
}
.item-editor-container .icon-toolbar-container .icon-container .icon {
  width: 64px;
  height: 64px;
}
.item-editor-container .icon-toolbar-container .icon-container .icon.no-icon {
  background-color: black;
}

