.item-editor-container {
    .icon-toolbar-container {
        border: 1px solid black;
        width: 80px;

        .icon-container {
            width: 100%;
            margin: 4px 8px 4px 8px;

            .icon {
                width: 64px;
                height: 64px;

                &.no-icon {
                    background-color: black;
                }
            }
        }
    }
}
