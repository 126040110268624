.slot-gap-wrapper {
    position: relative;
    width: 100%;
    height: 0;

    .slot-gap {
        position: absolute;
        top: -3px;
        left: 0px;
        width: 100%;
        height: 6px;
        z-index: 2;
        --active-highlight-color: #00ffff;
        --valid-highlight-color: #ccff00;
        cursor: pointer;
        animation-name: slot-gap-valid-highlight-radiate;
        animation-duration: 3s;
        animation-iteration-count: infinite;

        &:hover {
            background-color: #ccff0099;
            top: -10px;
            height: 20px;
        }

        @keyframes slot-gap-valid-highlight-radiate {
            0% {
                box-shadow: inset 0px 0px 12px var(--valid-highlight-color);
            }
            50% {
                box-shadow: inset 0px 0px 48px var(--valid-highlight-color);
            }
            100% {
                box-shadow: inset 0px 0px 12px var(--valid-highlight-color);
            }
        }
    }
}
