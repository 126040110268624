.module-tag {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  border-radius: 20px;
  padding: 8px;
  user-select: none;
  -webkit-user-select: none;
}
.module-tag .remove-button {
  width: 18px;
  height: 18px;
  font-size: 0.8em;
  border: 1px solid black;
  border-radius: 20px;
  color: black;
  padding: 1px;
  text-align: center;
  margin-left: 8px;
  cursor: pointer;
}
.module-tag .remove-button:hover {
  border: 1px solid red;
  color: red;
}
.module-tag .remove-button:active {
  border: 1px solid darkred;
  color: darkred;
}

