.card-body {
    min-height: 32px;
    width: calc(100% - 16px);
    border: 1px solid lightgray;
    border-radius: 8px;
    background-color: white;
    margin: 8px;
    padding: 12px;
    z-index: 5;
    filter: drop-shadow(4px 4px 2px #00000022);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &.clickable {
        &.disabled {
            &:hover {
                outline: 4px solid #b9b9b9;
            }
        }

        &:not(.disabled) {
            cursor: pointer;

            &:hover {
                outline: 4px solid #70e7f0;
            }

            &:active {
                outline: 6px solid #5fcdd4;
            }
        }
    }

    h2 {
        font-size: 1.5em;
        margin: 0 0 12px 0;
    }
}
