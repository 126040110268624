.inventory-item-info-slot-container {
  border-bottom: 1px solid darkgray;
  border-top: 1px solid darkgray;
}
.inventory-item-info-slot-container .icon-container .icon {
  width: 64px;
  height: 64px;
}
.inventory-item-info-slot-container .details-container {
  --active-highlight-color: #00ffff;
  --valid-highlight-color: #ccff00;
  --invalid-highlight-color: #ff0000;
  width: 100%;
  padding: 2px 4px;
  user-select: none;
  -webkit-user-select: none;
}
.inventory-item-info-slot-container .details-container.active {
  animation-name: item-slot-active-highlight-radiate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.inventory-item-info-slot-container .details-container.valid {
  animation-name: item-slot-valid-highlight-radiate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.inventory-item-info-slot-container .details-container.invalid {
  animation-name: item-slot-invalid-highlight-radiate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes item-slot-active-highlight-radiate {
  0% {
    box-shadow: inset 0px 0px 12px var(--active-highlight-color);
  }
  50% {
    box-shadow: inset 0px 0px 32px var(--active-highlight-color);
  }
  100% {
    box-shadow: inset 0px 0px 12px var(--active-highlight-color);
  }
}
@keyframes item-slot-valid-highlight-radiate {
  0% {
    box-shadow: inset 0px 0px 12px var(--valid-highlight-color);
  }
  50% {
    box-shadow: inset 0px 0px 48px var(--valid-highlight-color);
  }
  100% {
    box-shadow: inset 0px 0px 12px var(--valid-highlight-color);
  }
}
@keyframes item-slot-invalid-highlight-radiate {
  0% {
    box-shadow: inset 0px 0px 12px var(--invalid-highlight-color);
  }
  50% {
    box-shadow: inset 0px 0px 48px var(--invalid-highlight-color);
  }
  100% {
    box-shadow: inset 0px 0px 12px var(--invalid-highlight-color);
  }
}
.inventory-item-info-slot-container .details-container .title-bar {
  width: 100%;
}
.inventory-item-info-slot-container .item-tooltip {
  background-color: black;
  color: white;
}

