.meter-container {
  display: inline-grid;
  width: 400px;
  height: 36px;
  grid-template-columns: 4px calc(100% - 8px) 4px;
  grid-template-rows: 4px calc(100% - 8px) 4px;
}
.meter-container .top-left-border {
  grid-column: 1;
  grid-row: 1;
  background-color: gray;
}
.meter-container .top-border {
  grid-column: 2;
  grid-row: 1;
  background-color: gray;
}
.meter-container .top-right-border {
  grid-column: 3;
  grid-row: 1;
  background-color: gray;
}
.meter-container .left-border {
  grid-column: 1;
  grid-row: 2;
  background-color: gray;
}
.meter-container .meter-backing {
  grid-column: 2;
  grid-row: 2;
  background-color: lightgrey;
  -webkit-box-shadow: inset 0 0 20px 6px black;
  box-shadow: inset 0 2px 20px 6px black;
}
.meter-container .meter-backing .meter-fill-bar {
  grid-column: 2;
  grid-row: 2;
  height: 100%;
  background-color: white;
  border-right: 2px solid black;
  box-shadow: inset 8px -2px 10px 6px rgba(0, 0, 0, 0.7);
}
.meter-container .meter-overlay {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: row;
  user-select: none;
  font-size: 18pt;
  font-weight: bolder;
}
.meter-container .meter-overlay .current-value-field {
  width: 50%;
  height: 100%;
  text-align: center;
  color: white;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.meter-container .meter-overlay .current-value-field.editable {
  cursor: pointer;
}
.meter-container .meter-overlay .max-value-field {
  width: 50%;
  height: 100%;
  text-align: center;
  color: white;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1pt;
  -webkit-text-stroke-color: black;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.meter-container .meter-overlay .max-value-field.editable {
  cursor: pointer;
}
.meter-container .meter-overlay .current-value-editor-container {
  width: 50%;
  height: 100%;
}
.meter-container .meter-overlay .current-value-editor-container input[type=number] {
  width: 100%;
  height: 100%;
  font-size: 14pt;
}
.meter-container .meter-overlay .max-value-editor-container {
  width: 50%;
  height: 100%;
}
.meter-container .meter-overlay .max-value-editor-container input[type=number] {
  width: 100%;
  height: 100%;
  font-size: 14pt;
}
.meter-container .right-border {
  grid-column: 3;
  grid-row: 2;
  background-color: gray;
}
.meter-container .bottom-left-border {
  grid-column: 1;
  grid-row: 3;
  background-color: gray;
}
.meter-container .bottom-border {
  grid-column: 2;
  grid-row: 3;
  background-color: gray;
}
.meter-container .bottom-right-border {
  grid-column: 3;
  grid-row: 3;
  background-color: gray;
}

