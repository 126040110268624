.flex-row {
    display: flex;
    flex-direction: row;

    &.full-width {
        width: 100%;
    }

    &.half-width {
        width: 50%;
    }

    &.quarter-width {
        width: 25%;
    }

    &.auto-width {
        width: auto;
    }

    &.wrap {
        flex-wrap: wrap;
    }

    &.gap-4 {
        > *:first-child {
            margin-right: 2px;
        }

        > *:last-child:not(:only-child) {
            margin-left: 2px;
        }

        > *:not(:first-child):not(:last-child) {
            margin-left: 2px;
            margin-right: 2px;
        }
    }

    &.gap-8 {
        > *:first-child {
            margin-right: 4px;
        }

        > *:last-child:not(:only-child) {
            margin-left: 4px;
        }

        > *:not(:first-child):not(:last-child) {
            margin-left: 4px;
            margin-right: 4px;
        }
    }

    &.gap-12 {
        > *:first-child {
            margin-right: 6px;
        }

        > *:last-child:not(:only-child) {
            margin-left: 6px;
        }

        > *:not(:first-child):not(:last-child) {
            margin-left: 6px;
            margin-right: 6px;
        }
    }

    &.gap-16 {
        > *:first-child {
            margin-right: 8px;
        }

        > *:last-child:not(:only-child) {
            margin-left: 8px;
        }

        > *:not(:first-child):not(:last-child) {
            margin-left: 8px;
            margin-right: 8px;
        }
    }
}
