.generic-table-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}
.generic-table-container .generic-table-header-container {
  font-weight: bold;
}
.generic-table-container .generic-table-header-container .generic-table-header-cell {
  width: 100%;
  padding: 2px;
  user-select: none;
  -webkit-user-select: none;
  overflow-x: hidden;
}
.generic-table-container .generic-table-header-container .generic-table-header-cell:fixed-width {
  flex-shrink: 0;
}
.generic-table-container .generic-table-header-container .generic-table-header-cell:not(:last-child) {
  border-right: 1px solid darkgray;
}
.generic-table-container .generic-table-header-container .generic-table-header-cell.sortable {
  cursor: pointer;
}
.generic-table-container .generic-table-header-container .generic-table-header-cell.sortable:hover {
  background-color: skyblue;
}
.generic-table-container .generic-table-header-container .generic-table-header-cell.sortable:active {
  background-color: darkgray;
}
.generic-table-container .generic-table-header-container .generic-table-header-cell .label {
  margin-left: 4px;
  overflow-x: hidden;
}
.generic-table-container .generic-table-header-container .generic-table-header-cell .sort-button {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 4px;
}
.generic-table-container .generic-table-header-container .generic-table-header-cell .sort-button img {
  width: 20px;
  height: 20px;
}
.generic-table-container .generic-table-header-container .generic-table-header-cell .sort-button:hover {
  background-color: skyblue;
}
.generic-table-container .generic-table-header-container .generic-table-header-cell .sort-button:active {
  background-color: darkgray;
}
.generic-table-container .generic-table-rows-container .generic-table-row-wrapper {
  width: 100%;
}
.generic-table-container .generic-table-rows-container .generic-table-row-wrapper:not(:last-child) {
  border-bottom: 1px solid darkgray;
}
.generic-table-container .generic-table-rows-container .generic-table-row-wrapper.selected {
  background-color: lightblue;
}
.generic-table-container .generic-table-rows-container .generic-table-row-wrapper .generic-table-row {
  width: 100%;
}
.generic-table-container .generic-table-rows-container .generic-table-row-wrapper .generic-table-row .generic-table-cell {
  padding: 2px;
  margin: 20px;
  overflow-x: hidden;
  margin: 0;
}
.generic-table-container .generic-table-rows-container .generic-table-row-wrapper .generic-table-row-footer {
  width: 100%;
}

