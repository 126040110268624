.attribute-panel-container {
  width: 140px;
  box-sizing: border-box;
  border-radius: 10px;
}
.attribute-panel-container .title {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  user-select: none;
  font-size: 80%;
  font-weight: bold;
}
.attribute-panel-container .score-container {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
}
.attribute-panel-container .score {
  width: 80%;
  height: 100%;
  margin: 0 10%;
  padding: 8px 0;
  font-size: 1.2em;
  border: 1px black solid;
  border-radius: 5px;
  text-align: center;
  box-sizing: border-box;
  user-select: none;
}

