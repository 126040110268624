.inventory-item-dynamic-collection-container {
  width: 100%;
  border: 1px solid darkgray;
  border-radius: 6px;
}
.inventory-item-dynamic-collection-container .header {
  width: 100%;
  background-color: #c0c0c0;
  border-radius: 6px;
  cursor: pointer;
}
.inventory-item-dynamic-collection-container .header .header-text {
  margin: 14px 0 4px 6px;
  user-select: none;
  -webkit-user-select: none;
}
.inventory-item-dynamic-collection-container .toolbar {
  padding: 8px;
}
.inventory-item-dynamic-collection-container .toolbar .toolbar-button {
  width: 28px;
  height: 28px;
  padding: 2px;
}
.inventory-item-dynamic-collection-container .toolbar .toolbar-button img {
  width: 100%;
  height: 100%;
}
.inventory-item-dynamic-collection-container .toolbar .button-spacer {
  width: 8px;
}
.inventory-item-dynamic-collection-container .contents-wrapper.hidden {
  display: none;
}

