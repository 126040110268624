.character-trait-panel-container {
  width: 480px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid darkgray;
}
.character-trait-panel-container .active-indicator {
  width: 32px;
  border-radius: 10px 0 0 10px;
}
.character-trait-panel-container .active-indicator.editable {
  cursor: pointer;
}
.character-trait-panel-container .active-indicator.active {
  background-color: green;
}
.character-trait-panel-container .active-indicator.inactive {
  background-color: gray;
}
.character-trait-panel-container .details-container {
  left: -20px;
  width: 100%;
  height: 100%;
  margin: 8px;
}
.character-trait-panel-container .details-container .title {
  font-weight: bold;
  margin-right: 16px;
}
.character-trait-panel-container .details-container .type {
  font-style: italic;
}

