.checkbox-input-container {
    padding-top: 2px;

    .button-element {
        width: 20px;
        height: 20px;
        cursor: pointer;

        &:hover {
            background-color: #00000033;
        }
    }

    .label-element {
        margin: 2px 4px 0 4px;
        cursor: pointer;
        user-select: none;
    }
}
