@import "./style-constants.scss";

body {
    background-image: linear-gradient(
        to right,
        var(--aya-darker-gray) 0%,
        var(--aya-gray) 25%,
        var(--aya-gray) 75%,
        var(--aya-darker-gray) 100%
    );
}

h1 {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    font-family: "Times New Roman", Times, serif;
}

textarea {
    width: 100%;
}

label {
    user-select: none;
}

input[type="text"] {
    width: 100%;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #44444422;
    box-shadow: inset 0 0 3px grey;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb {
    border: 1px solid var(--aya-gray);
    background: var(--dark-parchment);
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--parchment);
}

::-webkit-scrollbar-thumb:active {
    background: var(--aya-dark-gray);
}

.center-align {
    margin-left: auto;
    margin-right: auto;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.flex-center {
    justify-content: center;
}

.flex-end {
    justify-content: flex-end;
}

.flex-space-around {
    justify-content: space-around;
}

#main-header {
    width: 100%;
    height: 250px;
}

#banner-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 64px;
    background-size: 300% 64px;
    border-bottom: 3px solid var(--aya-darker-blue);
    background-image: linear-gradient(
        179.5deg,
        var(--aya-light-blue),
        var(--aya-darker-blue)
    );
    background-attachment: local;
    animation: gradient-change 20s alternate infinite;

    #banner-left-margin {
        flex: 1;
    }

    #banner-contents {
        display: flex;
        flex-direction: row;
        width: 1104px;
        margin: 0;
        padding: 0;
    }

    #banner-right-margin {
        flex: 1;
    }
}

#nav-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    background-image: linear-gradient(
        to bottom,
        var(--aya-darker-gray) 0%,
        var(--aya-dark-gray) 20%,
        var(--aya-dark-gray) 85%,
        var(--aya-gray) 100%
    );
    border-bottom: 2px solid var(--aya-darker-gray);

    #nav-left-margin {
        flex: 1;
    }
    #nav-bar-container {
        width: 1104px;
        padding: 0 8px;

        nav {
            display: flex;
            flex-direction: row;
            text-decoration: none;

            a {
                text-decoration: none;
                user-select: none;
            }
        }

        .link-container {
            height: 34px;

            &:not(:first-child) {
                margin-left: 4px;
            }

            &:not(:last-child) {
                margin-right: 4px;
            }

            .link-decoration {
                height: 34px;
                padding: 8px;
                margin-right: 4px;
                width: auto;
                color: white;
                font-weight: bold;
                user-select: none;
            }

            .link,
            p {
                @extend .link-decoration;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    color: lightgray;
                }
            }
        }
    }
    #nav-right-margin {
        flex: 1;
    }
}

@keyframes gradient-change {
    0% {
        background-position-x: 0%;
    }
    100% {
        background-position-x: 100%;
    }
}

#page-container {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: row;

    #left-margin {
        flex: 1;
    }

    #content-container {
        width: 1104px;
        background-color: var(--aya-lighter-gray);
        border-right: 2px solid var(--aya-dark-gray);
        border-bottom: 2px solid var(--aya-dark-gray);
        border-left: 2px solid var(--aya-dark-gray);

        &.use-padding {
            padding: 10px;
        }
    }

    #right-margin {
        flex: 1;
    }
}
