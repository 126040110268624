h3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 8px;
}

input[type=checkbox] {
    margin-right: 8px;
}