.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row.full-width {
  width: 100%;
}
.flex-row.half-width {
  width: 50%;
}
.flex-row.quarter-width {
  width: 25%;
}
.flex-row.auto-width {
  width: auto;
}
.flex-row.wrap {
  flex-wrap: wrap;
}
.flex-row.gap-4 > *:first-child {
  margin-right: 2px;
}
.flex-row.gap-4 > *:last-child:not(:only-child) {
  margin-left: 2px;
}
.flex-row.gap-4 > *:not(:first-child):not(:last-child) {
  margin-left: 2px;
  margin-right: 2px;
}
.flex-row.gap-8 > *:first-child {
  margin-right: 4px;
}
.flex-row.gap-8 > *:last-child:not(:only-child) {
  margin-left: 4px;
}
.flex-row.gap-8 > *:not(:first-child):not(:last-child) {
  margin-left: 4px;
  margin-right: 4px;
}
.flex-row.gap-12 > *:first-child {
  margin-right: 6px;
}
.flex-row.gap-12 > *:last-child:not(:only-child) {
  margin-left: 6px;
}
.flex-row.gap-12 > *:not(:first-child):not(:last-child) {
  margin-left: 6px;
  margin-right: 6px;
}
.flex-row.gap-16 > *:first-child {
  margin-right: 8px;
}
.flex-row.gap-16 > *:last-child:not(:only-child) {
  margin-left: 8px;
}
.flex-row.gap-16 > *:not(:first-child):not(:last-child) {
  margin-left: 8px;
  margin-right: 8px;
}

