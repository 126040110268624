.character-sheet-header {
    width: 100%;
    background-color: darkgray;
    border-bottom: 3px double black;
    padding: 8px 24px;

    h2 {
        width: 60%;
        display: block;
        font-size: 1.5em;
        font-weight: bold;
        margin-block-start: 0.2em;
        margin-block-end: 0.2em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    h3 {
        text-align: left;
        font-size: 80%;
    }
}
