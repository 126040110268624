@import "../../../styles/main_style.css";
.modal-top-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  box-shadow: 3px 3px 8px gray;
  -webkit-box-shadow: 3px 3px 8px gray;
}
.modal-top-container hr {
  height: 1px;
  width: 100%;
}
.modal-top-container.default-style {
  background-color: var(--aya-lighter-gray);
  border: 1px solid var(--aya-gray);
  border-radius: 8px;
  padding: 8px;
}
.modal-top-container .x-button {
  flex-shrink: 1;
  width: 20;
  height: 20;
  cursor: pointer;
  padding: 2;
  user-select: none;
  font-weight: bold;
  color: var(--aya-gray);
}
.modal-top-container .x-button:hover {
  color: var(--aya-light-gray);
}
.modal-top-container .x-button:active {
  color: var(--aya-dark-gray);
}
.modal-top-container .cancel-button {
  font-size: 14pt;
  margin-right: 24px;
  padding: 2px 24px;
}
.modal-top-container .confirm-button {
  font-size: 14pt;
  margin-left: 24px;
  padding: 2px 24px;
}
.modal-top-container h1 {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2em;
  padding: 0 12px 4px 0;
  margin: 0;
  user-select: none;
}
.modal-top-container input[type=text],
.modal-top-container input[type=password],
.modal-top-container textarea {
  padding: 4px;
  border-color: var(--aya-dark-gray);
  border-radius: 3px;
  background-color: white;
}

