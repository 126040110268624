.character-sheet-abilities-panel {
    .schools-panel {
        width: 100%;
    }

    .toolbar {
        button {
            width: 28px;
            height: 28px;
            padding: 2px;
        }
    }
}
