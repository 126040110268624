.number-input-field-container {
  width: 100%;
  min-height: 27px;
  display: flex;
  flex-direction: row;
}
.number-input-field-container .label-field {
  margin-top: 6px;
  margin-right: 8px;
  font-weight: bold;
  text-wrap: nowrap;
}
.number-input-field-container .display-field {
  margin-top: 4px;
  width: 100%;
  overflow-x: hidden;
  padding: 2px;
}
.number-input-field-container .display-field.editable {
  cursor: pointer;
}
.number-input-field-container .display-field:hover {
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.number-input-field-container .display-field:active {
  background-color: rgba(0, 0, 0, 0.2666666667);
}
.number-input-field-container input[type=number] {
  width: 100%;
  height: 26px;
}
.number-input-field-container .editor-field {
  width: 100%;
}

