.tag-list-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > *:first-child {
        margin-right: 4px;
    }

    > *:last-child:not(:only-child) {
        margin-left: 4px;
    }

    > *:not(:first-child):not(:last-child) {
        margin-left: 4px;
        margin-right: 4px;
    }
}

.tag-container {
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    border-radius: 20px;
    padding: 8px;
    margin: 2px 0;
    user-select: none;
    -webkit-user-select: none;
    background-color: #00000016;

    .remove-button {
        width: 18px;
        height: 18px;
        font-size: 10pt;
        border: 1px solid black;
        border-radius: 20px;
        color: black;
        padding: 1px;
        text-align: center;
        margin-left: 8px;
        cursor: pointer;

        &:hover {
            border: 1px solid #aa0000;
            color: #aa0000;
            background-color: #ff000022;
        }

        &:active {
            border: 1px solid #880000;
            color: #880000;
            background-color: #ff000044;
        }
    }
}
