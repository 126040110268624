.character-sheet-container {
    .character-sheet {
        min-height: 840px;

        h2 {
            display: block;
            font-size: 1.5em;
            font-weight: bold;
            margin-block-start: 0.2em;
            margin-block-end: 0.2em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
        }

        .character-sheet-row-span-1 {
            width: 550px;
            padding: 8px;
        }

        .character-sheet-row-span-2 {
            width: 1100px;
            padding: 8px;

            &.no-padding {
                padding: 0;
            }
        }

        .hp-meter {
            width: 100%;
        }

        .ep-meter {
            width: 100%;
        }

        .ep-meter-adjust {
            margin-top: 6px;
            width: 120px;
        }

        .mp-meter {
            width: 100%;
        }

        .mp-meter-adjust {
            margin-top: 6px;
            width: 120px;
        }

        .exhaustion-label {
            margin-top: 10px;
            width: 140px;
        }

        .exhaustion-col {
            margin-top: 6px;
            width: 140px;
        }

        .dmg-switch-button {
            width: 24px;
            height: 24px;
        }

        .features-container {
            width: 100%;
            height: 540px;

            .features-content-panel {
                width: 100%;
                height: 100%;
                overflow-y: scroll;
            }

            .features-sub-container {
                width: 100%;
                height: 100%;

                .features-sub-content-panel {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
