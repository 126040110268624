.character-sheet-traits-panel {
    .toolbar {
        background-color: #c0c0c0;
        padding: 8px;
        border-radius: 6px;

        button {
            width: 28px;
            height: 28px;
            padding: 2px;
        }

        .header {
            margin-top: 6px;
        }
    }
}
