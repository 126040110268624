@import "../../../styles/style-constants.scss";

.tab-control-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 20px;

    .tab-component {
        padding: 2px 5px;
        margin: 5px 25px 5px 0;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;

        &:hover {
            background-color: var(--aya-light-gray);
        }

        &.disabled {
            cursor: default;
        }

        &.selected {
            font-weight: bold;
            border-bottom: 1px solid black;
        }
    }
}
