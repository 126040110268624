.checkbox-input-container {
  padding-top: 2px;
}
.checkbox-input-container .button-element {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.checkbox-input-container .button-element:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.checkbox-input-container .label-element {
  margin: 2px 4px 0 4px;
  cursor: pointer;
  user-select: none;
}

