.flex-column {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.full-width {
        width: 100%;
    }

    &.half-width {
        width: 50%;
    }

    &.quarter-width {
        width: 25%;
    }

    &.auto-width {
        width: auto;
    }

    &.gap-4 {
        > *:first-child {
            margin-bottom: 2px;
        }

        > *:last-child:not(:only-child) {
            margin-top: 2px;
        }

        > *:not(:first-child):not(:last-child) {
            margin-bottom: 2px;
            margin-top: 2px;
        }
    }

    &.gap-8 {
        > *:first-child {
            margin-bottom: 4px;
        }

        > *:last-child:not(:only-child) {
            margin-top: 4px;
        }

        > *:not(:first-child):not(:last-child) {
            margin-bottom: 4px;
            margin-top: 4px;
        }
    }

    &.gap-12 {
        > *:first-child {
            margin-bottom: 6px;
        }

        > *:last-child:not(:only-child) {
            margin-top: 6px;
        }

        > *:not(:first-child):not(:last-child) {
            margin-bottom: 6px;
            margin-top: 6px;
        }
    }

    &.gap-16 {
        > *:first-child {
            margin-bottom: 8px;
        }

        > *:last-child:not(:only-child) {
            margin-top: 8px;
        }

        > *:not(:first-child):not(:last-child) {
            margin-bottom: 8px;
            margin-top: 8px;
        }
    }
}
