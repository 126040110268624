.proficiency-panel-container {
  width: 100px;
  height: 120px;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
}
.proficiency-panel-container:hover .dynamicfill {
  fill: #aaa;
}
.proficiency-panel-container .proficiency-panel-frame {
  position: absolute;
  left: 10%;
  width: 90%;
}
.proficiency-panel-container .proficiency-panel-value-container {
  grid-column: 1;
  grid-row: 1;
}
.proficiency-panel-container .title {
  position: absolute;
  left: 10%;
  top: 10%;
  width: 90%;
  text-align: center;
  font-size: 80%;
  font-weight: bold;
  user-select: none;
}
.proficiency-panel-container .score-container {
  position: absolute;
  left: 18%;
  top: 30%;
  width: 74%;
  height: 30%;
  border: 1px black solid;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #bbb;
}
.proficiency-panel-container .score-field {
  width: 100%;
  height: 100%;
  font-size: 150%;
  align-content: center;
  text-align: center;
  box-sizing: border-box;
  user-select: none;
}
.proficiency-panel-container .score-field.editable:hover {
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.proficiency-panel-container .score-field.editable:active {
  background-color: rgba(0, 0, 0, 0.2666666667);
}
.proficiency-panel-container .score-editor {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  box-sizing: border-box;
  border-radius: 5px;
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
}
.proficiency-panel-container .score-editor .value {
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  align-content: center;
  text-align: center;
}
.proficiency-panel-container .score-editor .value:hover {
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.proficiency-panel-container .score-editor .value:active {
  background-color: rgba(0, 0, 0, 0.2666666667);
}
.proficiency-panel-container .score-editor .mod-button {
  cursor: pointer;
  width: 20px;
  height: 100%;
  border-radius: 5px;
  align-content: center;
  text-align: center;
}
.proficiency-panel-container .score-editor .mod-button:hover {
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.proficiency-panel-container .score-editor .mod-button:active {
  background-color: rgba(0, 0, 0, 0.2666666667);
}
.proficiency-panel-container .modifier-container {
  position: absolute;
  top: 68%;
  left: 20%;
  width: 70%;
  height: 22%;
  border: 1px black solid;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #bbb;
}
.proficiency-panel-container .modifier {
  width: 100%;
  height: 100%;
  font-size: 90%;
  align-content: center;
  text-align: center;
  box-sizing: border-box;
  user-select: none;
}

