.character-talent-panel-container {
    min-width: 480px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid darkgray;

    .details-container {
        width: 100%;
        height: 100%;
        margin: 8px;

        .title {
            font-weight: bold;
            margin-right: 16px;
        }

        .type {
            font-style: italic;
        }
    }
}

.talent-subtract-button {
    color: red;
    cursor: pointer;
    width: 20px;
    height: 100%;
    font-weight: bold;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;

    &:hover {
        color: white;
        background-color: #00000022;
    }

    &:active {
        color: darkred;
        background-color: #00000044;
    }
}

.talent-add-button {
    color: green;
    cursor: pointer;
    width: 20px;
    height: 100%;
    font-weight: bold;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;

    &:hover {
        color: white;
        background-color: #00000022;
    }

    &:active {
        color: darkgreen;
        background-color: #00000044;
    }
}
