.tooltip {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 200;
}
.tooltip.with-background {
  background-color: #5a5a5a;
}
.tooltip.text {
  background-color: #5a5a5a;
  color: white;
  padding: 4px;
}
.tooltip.shadow {
  box-shadow: 4px 4px 12px black;
}

