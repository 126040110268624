.multi-line-text-input-toggle-field-container {
  width: 100%;
  min-height: 24px;
}
.multi-line-text-input-toggle-field-container .label {
  font-weight: bold;
}
.multi-line-text-input-toggle-field-container .display-field {
  word-wrap: normal;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 24px;
  padding: 8px;
  border-radius: 5px;
}
.multi-line-text-input-toggle-field-container .display-field.placeholder {
  color: gray;
}
.multi-line-text-input-toggle-field-container .display-field.editable {
  cursor: pointer;
}
.multi-line-text-input-toggle-field-container .display-field.editable:hover {
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.multi-line-text-input-toggle-field-container .display-field.editable:active {
  background-color: rgba(0, 0, 0, 0.2666666667);
}

