* {
  box-sizing: border-box;
}

body, div, p {
  margin: 0;
  padding: 0;
}

:root {
  font-family: Arial, Helvetica, sans-serif;
  --parchment:#ebe8e0;
  --dark-parchment:#a79f8c;
  --aya-lighter-gray:#d6d6d6;
  --aya-light-gray:#adadad;
  --aya-gray:#838383;
  --aya-dark-gray:#5a5a5a;
  --aya-darker-gray:#272727;
  --aya-lighter-blue:#57acff;
  --aya-light-blue:#006ad2;
  --aya-blue:#0054a6;
  --aya-dark-blue:#00366a;
  --aya-darker-blue:#001a33;
  --aya-lighter-red:#fa8796;
  --aya-light-red:#e44e62;
  --aya-red:#911425;
  --aya-dark-red:#530812;
  --aya-darker-red:#1d0206;
  --aya-lighter-green:#87fa96;
  --aya-light-green:#4ee462;
  --aya-green:#1c9257;
  --aya-dark-green:#085325;
  --aya-darker-green:#1d0206;
  --aya-lighter-yellow:#fffbc6;
  --aya-light-yellow:#ece473;
  --aya-yellow:#bda834;
  --aya-dark-yellow:#695c11;
  --aya-darker-yellow:#2c2606;
  --aya-lighter-purple:#bb8ff5;
  --aya-light-purple:#894cda;
  --aya-purple:#6627b8;
  --aya-dark-purple:#3e1277;
  --aya-darker-purple:#180531;
  --default-font-color:white;
  --dark-font-color: black;
  --transparent-darken:#3d3d3d63;
}
@media (min-width: 1100px) {
  :root {
    font-size: 12pt;
  }
}
@media (max-width: 1099px) {
  :root {
    font-size: 20pt;
  }
}

.tab-control-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 20px;
}
.tab-control-container .tab-component {
  padding: 2px 5px;
  margin: 5px 25px 5px 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}
.tab-control-container .tab-component:hover {
  background-color: var(--aya-light-gray);
}
.tab-control-container .tab-component.disabled {
  cursor: default;
}
.tab-control-container .tab-component.selected {
  font-weight: bold;
  border-bottom: 1px solid black;
}

