.alignment-meter {
    display: inline-grid;
    width: 520px;
    height: 50px;
    user-select: none;
    -webkit-user-select: none;

    .bar-frame {
        width: 100%;
        height: 100%;
        grid-column: 1;
        grid-row: 1;
    }

    .fill-wrapper {
        position: relative;
        top: -2px;
        width: 100%;
        height: 100%;
        grid-column: 1;
        grid-row: 1;
        padding: 16px 20px 8px 20px;
    }
}
