.inventory-item-dynamic-collection-container {
    width: 100%;
    border: 1px solid darkgray;
    border-radius: 6px;

    .header {
        width: 100%;
        background-color: #c0c0c0;
        border-radius: 6px;
        cursor: pointer;

        .header-text {
            margin: 14px 0 4px 6px;
            user-select: none;
            -webkit-user-select: none;
        }
    }

    .toolbar {
        padding: 8px;

        .toolbar-button {
            width: 28px;
            height: 28px;
            padding: 2px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .button-spacer {
            width: 8px;
        }
    }

    .contents-wrapper {
        &.hidden {
            display: none;
        }
    }
}
