@import "../../../styles/main_style.css";

.modal-top-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    box-shadow: 3px 3px 8px gray;
    -webkit-box-shadow: 3px 3px 8px gray;

    hr {
        height: 1px;
        width: 100%;
    }

    &.default-style {
        background-color: var(--aya-lighter-gray);
        border: 1px solid var(--aya-gray);
        border-radius: 8px;
        padding: 8px;
    }

    .x-button {
        flex-shrink: 1;
        width: 20;
        height: 20;
        cursor: pointer;
        padding: 2;
        user-select: none;
        font-weight: bold;
        color: var(--aya-gray);

        &:hover {
            color: var(--aya-light-gray);
        }

        &:active {
            color: var(--aya-dark-gray);
        }
    }

    .cancel-button {
        font-size: 14pt;
        margin-right: 24px;
        padding: 2px 24px;
    }

    .confirm-button {
        font-size: 14pt;
        margin-left: 24px;
        padding: 2px 24px;
    }

    h1 {
        width: 100%;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.2em;
        padding: 0 12px 4px 0;
        margin: 0;
        user-select: none;
    }

    input[type="text"],
    input[type="password"],
    textarea {
        padding: 4px;
        //border: 1px solid black;
        border-color: var(--aya-dark-gray);
        border-radius: 3px;
        background-color: white;
    }
}
