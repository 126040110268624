.text-input-toggle-field-container {
    width: 100%;
    min-height: 28px;
    display: flex;
    flex-direction: row;

    .label-field {
        margin-top: 6px;
        margin-right: 8px;
        font-weight: bold;
        word-wrap: none;
        flex-wrap: nowrap;
        white-space: nowrap;
    }

    .display-field {
        width: 100%;
        overflow-x: hidden;
        margin: 4px 4px 0 0;
        padding: 2px;

        &.editable {
            cursor: pointer;

            &.empty {
                border-bottom: 1px solid black;
            }

            &:hover {
                background-color: #00000022;
            }

            &:active {
                background-color: #00000044;
            }
        }
    }

    input[type="text"] {
        width: 100%;
    }

    .editor-field {
        width: 100%;
    }
}
