.item-icon-container {
    width: 64px;
    height: 64px;
    margin: 0;
    padding: 0;

    .item-img-stack {
        display: inline-grid;
        width: inherit;
        height: inherit;

        .item-bg {
            grid-column: 1;
            grid-row: 1;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
        }

        .item-icon {
            grid-column: 1;
            grid-row: 1;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
        }

        .item-highlight {
            --active-highlight-color: #00ffff;
            --valid-highlight-color: #ccff00;
            --invalid-highlight-color: #ff0000;

            grid-column: 1;
            grid-row: 1;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            -ms-transform: translateX(1px) translateY(1px);
            transform: translateX(1px) translateY(1px);
            border: 3px double white;

            &.active {
                animation-name: icon-active-highlight-radiate;
                animation-duration: 3s;
                animation-iteration-count: infinite;
            }

            &.valid {
                animation-name: icon-valid-highlight-radiate;
                animation-duration: 3s;
                animation-iteration-count: infinite;
            }

            &.invalid {
                animation-name: icon-invalid-highlight-radiate;
                animation-duration: 3s;
                animation-iteration-count: infinite;
            }

            @keyframes icon-active-highlight-radiate {
                0% {
                    box-shadow: inset 0px 0px 12px var(--active-highlight-color);
                }
                50% {
                    box-shadow: inset 0px 0px 24px var(--active-highlight-color);
                }
                100% {
                    box-shadow: inset 0px 0px 12px var(--active-highlight-color);
                }
            }

            @keyframes icon-valid-highlight-radiate {
                0% {
                    box-shadow: inset 0px 0px 12px var(--valid-highlight-color);
                }
                50% {
                    box-shadow: inset 0px 0px 24px var(--valid-highlight-color);
                }
                100% {
                    box-shadow: inset 0px 0px 12px var(--valid-highlight-color);
                }
            }

            @keyframes icon-invalid-highlight-radiate {
                0% {
                    box-shadow: inset 0px 0px 12px
                        var(--invalid-highlight-color);
                }
                50% {
                    box-shadow: inset 0px 0px 24px
                        var(--invalid-highlight-color);
                }
                100% {
                    box-shadow: inset 0px 0px 12px
                        var(--invalid-highlight-color);
                }
            }
        }
    }
}
