.inventory-item-details-container {
  width: 400px;
  min-height: 20px;
  background-color: white;
  padding: 8px;
  border-radius: 4px;
}
.inventory-item-details-container .inventory-item-details-item-icon {
  margin-right: 4px;
}

