.character-equipment-container {
    .item_slot {
        width: 64px;
        height: 64px;
    }

    .item-slot-spacer {
        width: 64px;
        height: 64px;
    }
}
