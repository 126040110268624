.bordered_panel_wrapper_s1 {
  display: grid;
  width: 100%;
}
.bordered_panel_wrapper_s1 .bordered_panel_border {
  grid-column: 1;
  grid-row: 1;
  box-sizing: border-box;
  border-width: 72px;
  border-style: solid;
  border-color: purple;
  padding: 4px;
  border-image: url("../../../../assets/images/common/svg/borders/flair_border_01.svg");
  border-image-slice: 42% 42%;
  border-image-repeat: stretch;
}
.bordered_panel_wrapper_s1 .bordered_panel_contents {
  grid-column: 1;
  grid-row: 1;
  padding: 18px;
}

.bordered_panel_wrapper_s2 {
  display: grid;
}
.bordered_panel_wrapper_s2 .bordered_panel_border {
  grid-column: 1;
  grid-row: 1;
  box-sizing: border-box;
  border-width: 72px;
  border-style: solid;
  border-color: purple;
  padding: 4px;
  border-image: url("../../../../assets/images/common/svg/borders/flair_border_02.svg");
  border-image-slice: 42% 42%;
  border-image-repeat: stretch;
}
.bordered_panel_wrapper_s2 .bordered_panel_contents {
  grid-column: 1;
  grid-row: 1;
  padding: 10px;
}

