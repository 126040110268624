.labeled-text-field {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.labeled-text-field .ltf-label-field {
  margin-right: 8px;
  font-weight: bold;
  text-wrap: nowrap;
}
.labeled-text-field .ltf-value-field {
  width: 100%;
}

