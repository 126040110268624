.wallet-container {
    border: 1px solid darkgray;
    border-radius: 6px;

    .wallet-header {
        background-color: #c0c0c0;
        padding: 8px;
        border-radius: 6px 6px 0 0;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;

        .wallet-header-label {
            padding-top: 6px;
        }

        button {
            width: 28px;
            height: 28px;
            padding: 2px;
        }

        .add-button {
            width: 28px;
            height: 28px;
            padding: 2px;
        }
    }

    .currencies-wrapper {
        padding: 8px;

        &.hidden {
            display: none;
        }
    }

    .currency-container {
        margin: 2px 0;

        .currency-header {
            background-color: #c0c0c0;
            padding: 8px;
            border-radius: 6px 6px 0 0;
            cursor: pointer;

            &.collapsed {
                border-radius: 6px;
            }
        }

        .denominations-container {
            padding: 4px;
            border: 1px solid darkgray;
            border-radius: 0 0 6px 6px;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;

            .denomination-row {
                padding: 2px;

                .denomination-name {
                    padding: 6px 0 2px 0;
                }

                .denomination-plain-value {
                    padding: 4px 0 2px 0;
                }
            }

            .total-row {
                padding: 4px 4px 4px 2px;
                background-color: #c8c8c8;
            }
        }
    }
}
