.character-talent-panel-container {
  min-width: 480px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid darkgray;
}
.character-talent-panel-container .details-container {
  width: 100%;
  height: 100%;
  margin: 8px;
}
.character-talent-panel-container .details-container .title {
  font-weight: bold;
  margin-right: 16px;
}
.character-talent-panel-container .details-container .type {
  font-style: italic;
}

.talent-subtract-button {
  color: red;
  cursor: pointer;
  width: 20px;
  height: 100%;
  font-weight: bold;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}
.talent-subtract-button:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.talent-subtract-button:active {
  color: darkred;
  background-color: rgba(0, 0, 0, 0.2666666667);
}

.talent-add-button {
  color: green;
  cursor: pointer;
  width: 20px;
  height: 100%;
  font-weight: bold;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}
.talent-add-button:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.talent-add-button:active {
  color: darkgreen;
  background-color: rgba(0, 0, 0, 0.2666666667);
}

