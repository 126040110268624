.character-ability-panel-container {
    width: 480px;
    background-color: lightgray;
    box-sizing: border-box;
    border: 1px solid darkgray;
    border-radius: 10px;

    .details-container {
        width: 100%;
        height: 100%;
        margin: 8px;

        .header {
            padding-bottom: 8px;

            .title {
                width: auto;
                margin: 8px 16px 0 0;
                font-weight: bold;
                font-size: 1.2em;
            }

            .subtype {
                font-size: 11pt;
                margin-top: 2px;
                width: auto;
                font-style: italic;
            }
        }

        .icon-wrapper {
            width: 64px;
            margin-right: 8px;

            .icon {
                width: 64px;
                height: 64px;
                font-size: 14pt;
                text-align: center;
                position: relative;
                top: 50%;
                border: 1px solid black;
                border-radius: 6px;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                -webkit-user-select: none;
                user-select: none;
            }
        }

        .double-field {
            width: 45%;
            margin-bottom: 4px;
        }
    }
}
