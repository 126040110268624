.character-school-panel-container {
    width: 480px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid darkgray;

    .icon-container {
        width: 64px;
        height: 64px;
        margin: 16px 4px;

        .icon {
            width: 64px;
            height: 64px;
            border-radius: 8px;
        }
    }

    .details-container {
        width: 100%;
        height: 100%;
        margin: 8px;

        .title {
            font-size: 1.2em;
            font-weight: bold;
            margin-right: 16px;
        }

        .type {
            font-style: italic;
        }
    }

    .level-container {
        display: inline-grid;
        width: 60px;
        height: 60px;
        margin: 4px;

        .level-circle {
            width: 100%;
            height: 100%;
            grid-row: 1;
            grid-column: 1;
        }

        .level-value {
            width: 100%;
            height: 100%;
            grid-row: 1;
            grid-column: 1;

            .level-text {
                position: relative;
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                text-align: center;
                font-size: 20pt;
                -webkit-user-select: none;
                user-select: none;
            }
        }
    }
}

.school-subtract-button {
    color: red;
    cursor: pointer;
    width: 20px;
    height: 100%;
    font-weight: bold;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;

    &:hover {
        color: white;
        background-color: #00000022;
    }

    &:active {
        color: darkred;
        background-color: #00000044;
    }
}

.school-add-button {
    color: green;
    cursor: pointer;
    width: 20px;
    height: 100%;
    font-weight: bold;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;

    &:hover {
        color: white;
        background-color: #00000022;
    }

    &:active {
        color: darkgreen;
        background-color: #00000044;
    }
}
