.meter-container {
    display: inline-grid;
    width: 400px;
    height: 36px;
    grid-template-columns: 4px calc(100% - 8px) 4px;
    grid-template-rows: 4px calc(100% - 8px) 4px;

    .top-left-border {
        grid-column: 1;
        grid-row: 1;
        background-color: gray;
    }

    .top-border {
        grid-column: 2;
        grid-row: 1;
        background-color: gray;
    }

    .top-right-border {
        grid-column: 3;
        grid-row: 1;
        background-color: gray;
    }

    .left-border {
        grid-column: 1;
        grid-row: 2;
        background-color: gray;
    }

    .meter-backing {
        grid-column: 2;
        grid-row: 2;
        background-color: lightgrey;
        -webkit-box-shadow: inset 0 0 20px 6px black;
        box-shadow: inset 0 2px 20px 6px black;

        .meter-fill-bar {
            grid-column: 2;
            grid-row: 2;
            height: 100%;
            background-color: white;
            border-right: 2px solid black;
            box-shadow: inset 8px -2px 10px 6px rgba(0, 0, 0, 0.7);
        }
    }

    .meter-overlay {
        grid-column: 2;
        grid-row: 2;
        display: flex;
        flex-direction: row;
        user-select: none;

        font-size: 18pt;
        font-weight: bolder;

        .current-value-field {
            width: 50%;
            height: 100%;
            text-align: center;
            color: white;
            -webkit-text-fill-color: white;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black;
            position: relative;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);

            &.editable {
                cursor: pointer;
            }
        }

        .max-value-field {
            width: 50%;
            height: 100%;
            text-align: center;
            color: white;
            -webkit-text-fill-color: white;
            -webkit-text-stroke-width: 1pt;
            -webkit-text-stroke-color: black;
            position: relative;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);

            &.editable {
                cursor: pointer;
            }
        }

        .current-value-editor-container {
            width: 50%;
            height: 100%;

            input[type="number"] {
                width: 100%;
                height: 100%;
                font-size: 14pt;
            }
        }

        .max-value-editor-container {
            width: 50%;
            height: 100%;

            input[type="number"] {
                width: 100%;
                height: 100%;
                font-size: 14pt;
            }
        }
    }

    .right-border {
        grid-column: 3;
        grid-row: 2;
        background-color: gray;
    }

    .bottom-left-border {
        grid-column: 1;
        grid-row: 3;
        background-color: gray;
    }

    .bottom-border {
        grid-column: 2;
        grid-row: 3;
        background-color: gray;
    }

    .bottom-right-border {
        grid-column: 3;
        grid-row: 3;
        background-color: gray;
    }
}
