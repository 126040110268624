.item-icon-editor-container {
    .icon-list-container {
        width: 340px;
        height: 192px;
        overflow-y: scroll;

        .icon {
            cursor: pointer;

            &.selected {
                filter: opacity(0.5) drop-shadow(0 0 0 green);
            }
        }
    }

    .result-container {
        margin: 0 50% 0 50%;
    }

    .result-icon {
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}
