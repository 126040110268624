.number-input-field-container {
    width: 100%;
    min-height: 27px;
    display: flex;
    flex-direction: row;

    .label-field {
        margin-top: 6px;
        margin-right: 8px;
        font-weight: bold;
        text-wrap: nowrap;
    }

    .display-field {
        margin-top: 4px;
        width: 100%;
        overflow-x: hidden;
        padding: 2px;

        &.editable {
            cursor: pointer;
        }

        &:hover {
            background-color: #00000022;
        }

        &:active {
            background-color: #00000044;
        }
    }

    input[type="number"] {
        width: 100%;
        height: 26px;
    }

    .editor-field {
        width: 100%;
    }
}
