.character-sheet-abilities-panel .toolbar {
  background-color: #c0c0c0;
  padding: 8px;
  border-radius: 6px;
}
.character-sheet-abilities-panel .toolbar .header {
  margin-top: 6px;
}
.character-sheet-abilities-panel .toolbar button {
  width: 28px;
  height: 28px;
  padding: 2px;
}

