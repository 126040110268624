.flex-column {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.flex-column.full-width {
  width: 100%;
}
.flex-column.half-width {
  width: 50%;
}
.flex-column.quarter-width {
  width: 25%;
}
.flex-column.auto-width {
  width: auto;
}
.flex-column.gap-4 > *:first-child {
  margin-bottom: 2px;
}
.flex-column.gap-4 > *:last-child:not(:only-child) {
  margin-top: 2px;
}
.flex-column.gap-4 > *:not(:first-child):not(:last-child) {
  margin-bottom: 2px;
  margin-top: 2px;
}
.flex-column.gap-8 > *:first-child {
  margin-bottom: 4px;
}
.flex-column.gap-8 > *:last-child:not(:only-child) {
  margin-top: 4px;
}
.flex-column.gap-8 > *:not(:first-child):not(:last-child) {
  margin-bottom: 4px;
  margin-top: 4px;
}
.flex-column.gap-12 > *:first-child {
  margin-bottom: 6px;
}
.flex-column.gap-12 > *:last-child:not(:only-child) {
  margin-top: 6px;
}
.flex-column.gap-12 > *:not(:first-child):not(:last-child) {
  margin-bottom: 6px;
  margin-top: 6px;
}
.flex-column.gap-16 > *:first-child {
  margin-bottom: 8px;
}
.flex-column.gap-16 > *:last-child:not(:only-child) {
  margin-top: 8px;
}
.flex-column.gap-16 > *:not(:first-child):not(:last-child) {
  margin-bottom: 8px;
  margin-top: 8px;
}

