.article-container .hidden-field {
  display: none;
}
.article-container .map_container {
  width: 320px;
  height: 240px;
  margin: 4px;
  overflow: hidden;
}
.article-container .map_container img:first-child {
  width: 320px;
}
.article-container .article-category-bar {
  clear: both;
  width: 100%;
  padding: 8px;
  border: 1px solid darkgray;
  border-radius: 4px;
  font-size: 0.9em;
  background-color: rgba(0, 0, 0, 0.0666666667);
}
.article-container .article-footer {
  clear: both;
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  color: #444444;
  padding: 8px;
}
.article-container .article-footer .footer-left {
  width: 50%;
}
.article-container .article-footer .footer-right {
  width: 50%;
  text-align: right;
}

