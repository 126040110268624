.resistance-panel-container {
    width: 92px;
    box-sizing: border-box;
    border-radius: 10px;

    .title {
        width: 100%;
        font-size: 80%;
        font-weight: bold;
        text-align: center;
        box-sizing: border-box;
        user-select: none;
    }

    .score-container {
        width: 100%;
        height: 36px;
        margin: 4px;
        box-sizing: border-box;
    }

    .score {
        width: 80%;
        height: 100%;
        margin: 0 10%;
        padding: 8px 0;
        font-size: 80%;
        border: 1px black solid;
        border-radius: 5px;
        text-align: center;
        box-sizing: border-box;
        user-select: none;
    }
}
