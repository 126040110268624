.labeled-text-field {
    width: 100%;

    display: flex;
    flex-direction: row;

    .ltf-label-field {
        margin-right: 8px;
        font-weight: bold;
        text-wrap: nowrap;
    }

    .ltf-value-field {
        width: 100%;
    }
}
