.text-input-toggle-field-container {
  width: 100%;
  min-height: 28px;
  display: flex;
  flex-direction: row;
}
.text-input-toggle-field-container .label-field {
  margin-top: 6px;
  margin-right: 8px;
  font-weight: bold;
  word-wrap: none;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.text-input-toggle-field-container .display-field {
  width: 100%;
  overflow-x: hidden;
  margin: 4px 4px 0 0;
  padding: 2px;
}
.text-input-toggle-field-container .display-field.editable {
  cursor: pointer;
}
.text-input-toggle-field-container .display-field.editable.empty {
  border-bottom: 1px solid black;
}
.text-input-toggle-field-container .display-field.editable:hover {
  background-color: rgba(0, 0, 0, 0.1333333333);
}
.text-input-toggle-field-container .display-field.editable:active {
  background-color: rgba(0, 0, 0, 0.2666666667);
}
.text-input-toggle-field-container input[type=text] {
  width: 100%;
}
.text-input-toggle-field-container .editor-field {
  width: 100%;
}

